import { useQuery } from "@apollo/client";
import { ShipOwnerDTO } from "../../@types/ShipOwner";
import { ShipOwnerVesselDto } from "../../@types/ShipOwnerVessel";
import { GET_IHM_INSTALLED_ITEMS, GET_IHM_PENDING_FOR_APPROVAL_ITEMS, GET_SHIPOWNERS_QUERY, GET_VESSELS_BY_SHIPOWNER_QUERY, GET_VESSEL_DRAWINGS_BY_IHM } from "../../graphQL/Queries";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { DataGrid, GridColumns, GridRowModel, GridSelectionModel } from "@mui/x-data-grid";
import { parseDate } from "../../utils/formatTime";
import { Box, Container, Grid } from "@mui/material";
import { TableListToolbar } from "../table";
import ShipOwnerAndVesselSelects from "../ShipOwnerAndVesselSelects";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import RemoveIhmInstallationMapperModal from "../map/removeIhmInstallation/RemoveIhmInstallationMapperModal";
import ReplaceIhmInstallationMapperModal from "../map/replaceIhmInstallation/ReplaceIhmInstallationMapperModal";
import PlaceSingleIhmPositionModal from "../modal/PlaceSingeIhmPositionModal";
import { IhmItemMaterialRowDTO } from "../../@types/IHMMaterialRow";
import { MapperActionsEnum } from "../map/Mapper";


const useStyles = makeStyles(() => ({
    customStyle: {
      "& .MuiDataGrid-toolbarContainer": {
        background: "#ffffff",
      },
    },
    panel: {
      "& .MuiNativeSelect-select": {
        backgroundColor: "red",
        color: "red",
      },
    },
    actionBtn: {
      "&.MuiLoadingButton-root": {
        backgroundColor: "#0079C1",
        borderRadius: "25px",
      },
    },
  }));


// Define Queries
const getIhmsQuery = (
    searchToken: string,
    selectedShipownerVessel: ShipOwnerVesselDto | undefined,
    limit: number,
    page: number

  ) =>
    useQuery(GET_IHM_INSTALLED_ITEMS, {
      variables: {
        searchToken: searchToken,
        shipOwnerId: (selectedShipownerVessel && selectedShipownerVessel.shipOwnerDto && selectedShipownerVessel.shipOwnerDto.id ) ? selectedShipownerVessel.shipOwnerDto.id : 0,
        vesselId: (selectedShipownerVessel && selectedShipownerVessel.vesselDto && selectedShipownerVessel.vesselDto.id) ? parseInt(selectedShipownerVessel.vesselDto.id) : 0,
        limit: limit,
        page: page,
        showOnlyNotPlaced: true,
      },
      skip: selectedShipownerVessel == undefined || selectedShipownerVessel.shipOwnerDto == undefined || selectedShipownerVessel.shipOwnerDto.id == undefined || (selectedShipownerVessel.shipOwnerDto.id != undefined && selectedShipownerVessel.shipOwnerDto.id === '0'),
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      fetchPolicy: "network-only",
    });

     const useGetVesselDrawingEntitiesQuery = (ihmId: number | undefined) =>
    useQuery(GET_VESSEL_DRAWINGS_BY_IHM, {
      variables: {
        ihmId: ihmId,
      },
      skip: !ihmId,
      onError(err) {
        toast.configure();
        toast.error(`Error: ${err.message}`);
      },
      fetchPolicy: "network-only",
    });


export default function NotPlacedIhmsTable() {
  // native props
  const classesNew = useStyles();
  const navigate = useNavigate();
  const [searchToken, setSearchToken] = useState("");
  const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
  const [selectedIhmPositionId, setSelectedIhmPositionId] = useState<number>();
  const [selectedShipOwnerIdAndVesselId, setSelectedShipOwnerIdAndVesselId] = useState<ShipOwnerVesselDto | undefined>();
  const [
    isRemoveIhmInstallationMapperModalOpen,
    setIsRemoveIhmInstallationMapperModalOpen,
  ] = useState(false);
  const [
    isReplaceIhmInstallationMapperModalOpen,
    setIsReplaceIhmInstallationMapperModalOpen,
  ] = useState(false);
  const [isInstallIhmModalOpen, setIsInstallIhmModalOpen] = useState(false);
  // dataGrid props
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [isOpen, setIsOpen] = useState(false);
  // Use Queries
  const getIhmsQueryResponse = getIhmsQuery(
    searchToken,
    selectedShipOwnerIdAndVesselId,
    rowsPerPage,
    page
  );

  // --------------------------------- hooks -------------------------------------------------
  useEffect(() => {
    if (
      getIhmsQueryResponse.data &&
      getIhmsQueryResponse.data.findIhmInstalledItems
    ) {
      const tableRowsArray: GridRowModel[] = [];

      for (const storedIhm of getIhmsQueryResponse.data.findIhmInstalledItems) {
        const dataTableRow: GridRowModel = {
          id: storedIhm.ihmPositionId,
        };
        dataTableRow.ihmPositionId = storedIhm.ihmPositionId;
        dataTableRow.ihmId = storedIhm.ihmId;
        dataTableRow.vesselName = storedIhm.vesselName;
        dataTableRow.vesselImo = storedIhm.vesselImo;
        dataTableRow.inventorySubPart = `Sub Part ${storedIhm.inventorySubPart}`;
        dataTableRow.equipmentDescription = storedIhm.equipmentDescription;
        dataTableRow.productName = storedIhm.productName;
        dataTableRow.installedQuantity = storedIhm.installedQuantity;
        dataTableRow.location = storedIhm.location;
        dataTableRow.referenceNumber = storedIhm.referenceNumber;

        dataTableRow.dateAdded =
          typeof storedIhm.dateAdded === "string" &&
          parseDate(storedIhm.dateAdded) !== "Invalid Date"
            ? parseDate(storedIhm.dateAdded)
            : storedIhm.dateAdded;

        tableRowsArray.push(dataTableRow);
      }

      setDataTableRows(tableRowsArray);
    }
  }, [getIhmsQueryResponse.data]);

const [rowtoPace, setrowtoPace] = useState<IhmItemMaterialRowDTO>();
  useEffect(()=>{
    if(selectionModel[0]){
      const selectedMaterialRow: IhmItemMaterialRowDTO = {
        id: parseInt(selectionModel[0].toString()),
      };
      const row = dataTableRows.find((x)=> x.id == parseInt(selectionModel[0].toString()));
      selectedMaterialRow.ihmId = row.ihmId;
      selectedMaterialRow.quantity = row.installedQuantity;
      selectedMaterialRow.positionId = row.ihmPositionId;
      selectedMaterialRow.x = null;
      selectedMaterialRow.y = null;
      setrowtoPace(selectedMaterialRow);
    }

  }, [selectionModel]);

  // --------------------------------- handlers -----------------------------------------------
  const handleFilterByName = (filterName: string) => {
    setSearchToken(filterName);
    setPage(0);
  };

  // --------------------------------- Table Setup -----------------------------------------------
  const TABLE_HEAD: GridColumns = [
    {
      field: "vesselName",
      headerName: "Vessel Name",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "vesselImo",
      headerName: "Vessel IMO",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "inventorySubPart",
      headerName: "IHM Sub Part",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "equipmentDescription",
      headerName: "Description",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "productName",
      headerName: "Component / Item",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "installedQuantity",
      headerName: "Installed Quantity",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "location",
      headerName: "Location",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "referenceNumber",
      headerName: "Reference Number",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "dateAdded",
      headerName: "Date Added",
      width: 180,
      type: "string",
      sortable: false,
    },
  ];

  // ------------------------------------------------------------------------------------------
  return (
    <Container maxWidth={"xl"}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0.5, justifyContent: "space-between" }}
      >
        <Box sx={{ flex: "1" }}>
          <TableListToolbar
            numSelected={0}
            filterName={searchToken}
            onFilterName={handleFilterByName}
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <ShipOwnerAndVesselSelects
            loadFromSessionShipOwnerAndVessels={true}
            selectedShipOwnerId={undefined}
            selectedVesselId={undefined}
            setSelectedShipOwnerIdAndVesselId={setSelectedShipOwnerIdAndVesselId}
            manualSetAndFindShipOwnerAndVessel={false}
            stateOfLoadingFind={false}
          />
        </Box>
        <Box sx={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            variant="contained"
            disabled={selectionModel.length != 1}
            onClick={() => {
              setSelectedIhmPositionId(
                selectionModel[0] ? parseInt(`${selectionModel[0]}`) : undefined
              );
              setIsInstallIhmModalOpen(true);
            }}
            className={classesNew.actionBtn}
          >
            Place position
          </LoadingButton>

        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Box
          sx={{
            height: 550,
            width: 1,
            "& .super-app-theme--2": {
              bgcolor: () => "rgba(91, 229, 132, 0.15)",
            },
            "& .super-app-theme--1": {
              bgcolor: () => "rgba(255, 244, 165, 0.5)",
            },
            marginTop: 4,
          }}
        >
          <DataGrid
            columns={TABLE_HEAD}
            rows={dataTableRows}
            className={classesNew.customStyle}
            loading={getIhmsQueryResponse.loading}
            disableColumnFilter
            // --------------------selection props
            disableSelectionOnClick
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) =>
              setSelectionModel(newSelectionModel)
            }
            selectionModel={selectionModel}
            // --------------------pagination props
            pagination
            paginationMode="server"
            rowCount={dataTableRows.length}
            rowsPerPageOptions={[20, 50, 100]}
            pageSize={rowsPerPage}
            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        </Box>
      </Box>

      {selectedIhmPositionId && (
        <RemoveIhmInstallationMapperModal
          isOpenPreview={isRemoveIhmInstallationMapperModalOpen}
          onClosePreview={() => {
            setIsRemoveIhmInstallationMapperModalOpen(false);
          }}
          ihmPositionId={selectedIhmPositionId}
        />
      )}
      {selectedIhmPositionId && (
        <ReplaceIhmInstallationMapperModal
          isOpenPreview={isReplaceIhmInstallationMapperModalOpen}
          onClosePreview={() => {
            setIsReplaceIhmInstallationMapperModalOpen(false);
          }}
          ihmPositionId={selectedIhmPositionId}
        />
      )}
      <PlaceSingleIhmPositionModal
        isOpenPreview={isInstallIhmModalOpen}
        onClosePreview={() => {
          setIsInstallIhmModalOpen(false);
          setIsOpen(false);
        }}
        selectedMaterialRow={rowtoPace ? rowtoPace : {}}
        isPreviewMode={
          rowtoPace?.x === null || rowtoPace?.y === null
            ? false
            : true
        }
        allowedActions={
          rowtoPace?.x === null || rowtoPace?.y === null
            ? MapperActionsEnum.IS_PLACE_ON_MAP
            : MapperActionsEnum.IS_VIEW
        }
      />



    </Container>
  );
}