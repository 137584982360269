import React, { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import ShipOwnerUserNewForm from "components/dashboard/shipOwnerUsers/ShipOwnerUserNewForm";
import { useKeycloak } from "@react-keycloak/web";
import { useAuth } from "contexts/auth";
import PurchaseOrderItemForm from "../components/PO/PurchaseOrderItems/PurchaseOrderItemForm";
import SupplierInventory from "../pages/Suppliers/SupplierInventory";
import MaintainerLandingPage from "../pages/Maintainers/MaintainerLandingPage";
import { UPDATE_APP_USER_LAST_LOGIN } from "../graphQL/Mutations";
import { useMutation } from "@apollo/client";
import SupplierCompliancePage from "../pages/Compliance/SupplierCompliancePage";
import DocumentPreviewPage from "../pages/public/DocumentPreviewPage";
import TermsPage from "../pages/public/TermsPage";
import DanaosRequests from "../components/PO/PODanaos/DanaosRequests";
import ManageIHMsPage from "../pages/IHM/ManageIHMs/ManageIHMsPage";



// eslint-disable-next-line react/display-name
const Loadable = (Component: React.ElementType) => (props: any) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [selectedShipOwner, setSelectedShipOwner] = useState<string | null>(
    null
  );
  const [selectedVessel, setSelectedVessel] = useState<string | null>(null);
  const [navigationHelper, setNavigationHelper] = useState<boolean>(false);

  const selectAshipOwner = (shipOwnerName: string): void => {
    if (shipOwnerName && shipOwnerName.length > 0) {
      setSelectedShipOwner(shipOwnerName);
    } else {
      setSelectedShipOwner("");
    }
  };

  const selectAvessel = (vesselName: string): void => {
    if (vesselName && vesselName.length > 0) {
      setSelectedVessel(vesselName);
    } else {
      setSelectedVessel("");
    }
  };

  const { initialized, keycloak } = useKeycloak();


  const [UpdateAppUserLastLogin, UpdateAppUserLastLoginProps] = useMutation(
    UPDATE_APP_USER_LAST_LOGIN,
    {
      fetchPolicy: "network-only",
    }
  );

  const useUpdateAppUserLastLogin = () => {
    UpdateAppUserLastLogin({
      onError(err) {
        console.log(err);
      },
    });
  };

  if (!initialized) {
    return <h3>Loading ... !!!</h3>;
  }

  const [userRole, setUserRole] = useState<undefined | string>(undefined);

  // Get User Role from keycloack
  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
            updateLastLogin();
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
            updateLastLogin();
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
            updateLastLogin();
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  const updateLastLogin = () => {
    const pageAccessedByReload =
      (window.performance.navigation &&
        window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType("navigation")
        .map((nav) => nav.name)
        .includes("reload");
    if (!pageAccessedByReload) {
      useUpdateAppUserLastLogin();
    }
  };

  return useRoutes([
    {
      path: "app/verification/:uuid",
      element: <DocumentPreviewPage key={"1"} />,
    },
    {
      path: "app/terms",
      element: <TermsPage />,
    },
    {
      path: "/",
      element: <Navigate to="/dashboard/homepage" replace />,
    },
    {
      path: "dashboard",
      element: (
        <>
          <RequireAuth>
            <DashboardLayout
              selectedShipOwner={selectedShipOwner}
              selectedVessel={selectedVessel}
              navigationHelper={navigationHelper}
            />
          </RequireAuth>
        </>
      ),
      children: [
        {
          path: "homepage",
          element: (
            <RequireAuth>
              <MaintainerLandingPage
                setNavigationHelper={setNavigationHelper}
              />
            </RequireAuth>
          ),
        },
        {
          path: "supplierCompliance",
          element: (
            <RequireAuth>
              <SupplierCompliancePage />
            </RequireAuth>
          ),
        },
        {
          path: "shipowners",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/shipowners/all" replace />,
            },
            {
              path: "all",
              element: (
                <RequireAuth>
                  <ShipOwnersGrid setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: "new",
              element: (
                <RequireAuth>
                  <ShipOwnerCreate setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: ":shipOwnerId/edit",
              element: (
                <RequireAuth>
                  <ShipOwnerEdit setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: ":shipOwnerId/user/new",
              element: (
                <RequireAuth>
                  <ShipOwnerUserNewForm
                    setNavigationHelper={setNavigationHelper}
                  />
                </RequireAuth>
              ),
            },
            {
              path: ":shipOwnerId/user/:selectedShipOwnerUserId/edit",
              element: (
                <RequireAuth>
                  <ShipOwnerUserNewForm
                    setNavigationHelper={setNavigationHelper}
                  />
                </RequireAuth>
              ),
            },
          ],
        },
        {
          path: "suppliers",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/suppliers/all" replace />,
            },
            {
              path: "all",
              element: (
                <RequireAuth>
                  <SuppliersGrid setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: "new",
              element: (
                <RequireAuth>
                  <SupplierHandle setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: ":supplierId/edit/mainDash/:mainDash",
              element: (
                <RequireAuth>
                  <SupplierHandle setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: ":supplierId/user/new",
              element: (
                <RequireAuth>
                  <AppUserForm setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: ":supplierId/user/:selectedSupplierUserId/edit",
              element: (
                <RequireAuth>
                  <AppUserForm setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: "registration/edit",
              element: (
                <RequireAuth>
                  <SupplierRegistration
                    setNavigationHelper={setNavigationHelper}
                  />
                </RequireAuth>
              ),
            },
          ],
        },
        {
          path: "superuser",
          children: [
            {
              path: "",
              element: (
                <Navigate to="/dashboard/superuser/all" replace />
              ),
            },
            {
              path: "all",
              element: (
                <RequireAuth>
                  <ShipOwnerSuperUser setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            }
          ]
        },
        {
          path: "manageSpareParts",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/manageSpareParts/manageSparePartsDashboard" replace />,
            },
            {
              path: "manageSparePartsDashboard",
              element: (
                <RequireAuth>
                  <ManageSpareParts setNavigationHelper={setNavigationHelper}/>
                </RequireAuth>
              ),
            }
          ]
        },
        {
          path: "manageComponents",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/manageComponents/manageComponentsDashboard" replace />,
            },
            {
              path: "manageComponentsDashboard",
              element: (
                <RequireAuth>
                  <ManageComponents setNavigationHelper={setNavigationHelper}/>
                </RequireAuth>
              ),
            }
          ]
        },
        {
          path: "manageSparepartOfComponents",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/manageSparepartOfComponents/manageSparepartOfComponentsDashboard" replace />,
            },
            {
              path: "manageSparepartOfComponentsDashboard",
              element: (
                <RequireAuth>
                  <ManageComponentsAndSpareParts setNavigationHelper={setNavigationHelper}/>
                </RequireAuth>
              ),
            }
          ]
        },
        {
          path: "manageSpSystems",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/manageSpSystems/manageSpSystemsDashboard" replace/>
            },
            {
              path: "manageSpSystemsDashboard",
              element: (
                <RequireAuth>
                  <ManageSpSystems setNavigationHelper={setNavigationHelper}/>
                </RequireAuth>
              )
            }
          ]
        },
        {
          path: "mySparepartsQuotations",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/mySparepartsQuotations/mySparepartsQuotationsDashboard" replace />,
            },
            {
              path: "mySparepartsQuotationsDashboard",
              element: (
                <RequireAuth>
                  <MySparepartsOrders setNavigationHelper={setNavigationHelper}/>
                </RequireAuth>
              ),
            }
          ]
        },
        {
          path: "manageSerialNumbers",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/manageSerialNumbers/manageSerialNumbersDashboard" replace />,
            },
            {
              path: "manageSerialNumbersDashboard",
              element: (
                <RequireAuth>
                  <ManageSerialNumbers setNavigationHelper={setNavigationHelper}/>
                </RequireAuth>
              ),
            }
          ] 
        },
        {
          path: "purchasedOrders",
          children: [
            {
              path: "",
              element: (
                <Navigate to="/dashboard/purchasedOrders/import" replace />
              ),
            },
            {
              path: "import",
              element: (
                <RequireAuth>
                  <ManageWizardsPage
                    setNavigationHelper={setNavigationHelper}
                  />
                </RequireAuth>
              ),
            },
            {
              path: "new",
              element: (
                <RequireAuth>
                  <ImportPurchaseOrderPage
                    setNavigationHelper={setNavigationHelper}
                  />
                </RequireAuth>
              ),
            },
            {
              path: "shipOwner/:selectedShipOwnerId/wizard/:selectedWizardId/step/:selectedWizardStep/manage",
              element: (
                <RequireAuth>
                  <ImportPurchaseOrderPage
                    setNavigationHelper={setNavigationHelper}
                  />
                </RequireAuth>
              ),
            },
            {
              path: "list",
              element: (
                <RequireAuth>
                  {
                    <PurchaseOrdersPage
                      userRole={userRole}
                      isEdit={false}
                      selectAshipOwner={selectAshipOwner}
                      selectAvessel={selectAvessel}
                      setNavigationHelper={setNavigationHelper}
                    />
                  }
                </RequireAuth>
              ),
            },
            {
              path: "items",
              children: [
                {
                  path: ":shipOwnerId/:vesselId/new/",
                  element: (
                    <RequireAuth>
                      <PurchaseOrderItemForm
                        setNavigationHelper={setNavigationHelper}
                      />
                    </RequireAuth>
                  ),
                },
                {
                  path: ":purchaseOrderItemId/edit/",
                  element: (
                    <RequireAuth>
                      <PurchaseOrderItemForm
                        setNavigationHelper={setNavigationHelper}
                      />
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: "myrequests",
              element: (
                <RequireAuth>
                  <MyRequestsPage
                    setNavigationHelper={setNavigationHelper}
                    isEdit={false}
                  />
                </RequireAuth>
              ),
            },
            {
              path: "searchpoitems",
              element: (
                <RequireAuth>
                  <SearchPOItemsPage
                    setNavigationHelper={setNavigationHelper}
                    isEdit={false}
                  />
                </RequireAuth>
              ),
            },
            {
              path: "danaosrequests",
              element: (
                <RequireAuth>
                  <DanaosRequests
                  //setNavigationHelper={setNavigationHelper}
                  //isEdit={false}
                  />
                </RequireAuth>
              ),
            },
            {
              path: "supplierInventory",
              element: (
                <RequireAuth>
                  <SupplierInventory
                    setNavigationHelper={setNavigationHelper}
                  />
                </RequireAuth>
              ),
            },
          ],
        },
        {
          path: "vessels",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/vessels/dashboard" replace />,
            },
            {
              path: "importvessels",
              element: (
                <>
                  <RequireAuth>
                    <ImportVessels setNavigationHelper={setNavigationHelper} />
                  </RequireAuth>
                </>
              ),
            },
            {
              path: "dashboard",
              element: (
                <>
                  <RequireAuth>
                    <DashboardVessels
                      selectAshipOwner={selectAshipOwner}
                      selectAvessel={selectAvessel}
                      setNavigationHelper={setNavigationHelper}
                    />
                  </RequireAuth>
                </>
              ),
            },
            {
              path: "new",
              element: (
                <RequireAuth>
                  <VesselsForms setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: ":selectedVesselId/edit",
              element: (
                <RequireAuth>
                  <VesselsForms setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
          ],
        },
        {
          path: "ihms",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/ihms/dashboard" replace />,
            },
            {
              path: "importihms",
              element: (
                <RequireAuth>
                  <ImportIHMs setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: "manageihms",
              element: (
                <RequireAuth>
                  <ManageIHMsPage setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: "ihmemailreports",
              element: (
                <RequireAuth>
                  <EmailReports setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              )
            },
            {
              path: "dashboard",
              element: (
                <RequireAuth>
                  <DashboardIHMs
                    selectAshipOwner={selectAshipOwner}
                    selectAvessel={selectAvessel}
                    setNavigationHelper={setNavigationHelper}
                  />
                </RequireAuth>
              ),
            },
            {
              path: "new/shipowner/:selectedShipOwnerId/vessel/:selectedVesselId",
              element: (
                <RequireAuth>
                  <IHMItemDashboard setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },

            {
              path: ":selectedIHMId/edit",
              element: (
                <RequireAuth>
                  <IHMItemDashboard setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
            {
              path: ":selectedIHMId/edit/position/:selectedPositionId",
              element: (
                <RequireAuth>
                  <IHMItemDashboard setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
          ],
        },
        {
          path: "user",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/user/profile" replace />,
            },
            {
              path: "profile",
              element: (
                <RequireAuth>
                  <UserProfilePage setNavigationHelper={setNavigationHelper} />
                </RequireAuth>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "/public/myrequests",
      element: (
        <MyRequestsPage isPublic setNavigationHelper={setNavigationHelper} />
      ),
    },
    {
      path: "/public/register",
      element: (
        <RegistrationPage isPublic setNavigationHelper={setNavigationHelper} />
      ),
    },
    {
      path: "/public/shareddocs/:uuid1/:uuid2",
      element: (
        <SharedDocs />
      ),
    }
  ]);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const { initialized, keycloak } = useKeycloak();
  const { User } = useAuth();

  if (initialized && !keycloak?.authenticated && !User.IsAuthenticated) {
    return <Login />;
  }

  if (keycloak == undefined || keycloak === null) {
    return <Login />;
  }

  const token = window.sessionStorage.getItem("jwt");
  if (token === "undefined" || token === null) {
    return <Login />;
  }

  return children;
}

// -------------------------Authentication-------------------------------------
const Login = Loadable(lazy(() => import("../pages/Login/Login")));
// -------------------------Dashboard------------------------------------------
const DashboardLayout = Loadable(lazy(() => import("../layouts/dashboard")));
// ShipOwners
const ShipOwnersGrid = Loadable(
  lazy(() => import("../pages/ShipOwners/ShipOwnersGrid"))
);
const ShipOwnerCreate = Loadable(
  lazy(() => import("../pages/ShipOwners/ShipOwnerCreate"))
);
const ShipOwnerEdit = Loadable(
  lazy(() => import("../pages/ShipOwners/ShipOwnerEdit"))
);
// Suppliers
const SuppliersGrid = Loadable(
  lazy(() => import("../pages/Suppliers/SuppliersGrid"))
);
const SupplierHandle = Loadable(
  lazy(() => import("../pages/Suppliers/SupplierHandle"))
);
const SupplierRegistration = Loadable(
  lazy(() => import("../pages/Suppliers/Registration/SupplierRegistration"))
);
const AppUserForm = Loadable(
  lazy(() => import("../components/dashboard/appUsers/AppUserForm"))
);
const ManageSpareParts = Loadable(
  lazy(() => import("../pages/Suppliers/ManageSpareParts"))
);
const ManageComponents = Loadable(
  lazy(() => import("../pages/Suppliers/ManageComponents"))
);
const ManageComponentsAndSpareParts = Loadable(
  lazy(() => import("../pages/Suppliers/ManageComponentsAndSpareParts"))
);
const ManageSpSystems = Loadable(
  lazy(() => import("../pages/Suppliers/ManageSpSystems"))
);
const MySparepartsOrders = Loadable(
  lazy(() => import("../pages/Suppliers/MySparepartsOrders"))
);
const ManageSerialNumbers = Loadable(
  lazy(() => import("../pages/Suppliers/ManageSerialNumbers"))
);
// PurchaseOrder
const ImportPurchaseOrderPage = Loadable(
  lazy(() => import("../pages/PurchaseOrders/ImportPurchaseOrderPage"))
);
const PurchaseOrdersPage = Loadable(
  lazy(() => import("../pages/PurchaseOrders/PurchaseOrdersPage"))
);
const MyRequestsPage = Loadable(
  lazy(() => import("../pages/PurchaseOrders/MyRequestsPage"))
);
const RegistrationPage = Loadable(
  lazy(() => import("../pages/Suppliers/Registration/RegistrationPage"))
);
const SharedDocs = Loadable(
  lazy(() => import("../pages/SharedDocs/SharedDocs"))
);
const ManageWizardsPage = Loadable(
  lazy(() => import("../pages/PurchaseOrders/ManageWizardsPage"))
);
//Vessels
const ImportVessels = Loadable(
  lazy(() => import("../pages/Vessels/ImportVessels/ImportVessels"))
);
const DashboardVessels = Loadable(
  lazy(() => import("../pages/Vessels/DashboardVessels/DashboardVessels"))
);
const VesselsForms = Loadable(
  lazy(() => import("../components/dashboard/vessels/VesselsForms"))
);
//IHM
const ImportIHMs = Loadable(
  lazy(() => import("../pages/IHM/ImportIHMs/ImportIHMs"))
);
const DashboardIHMs = Loadable(
  lazy(() => import("../pages/IHM/DashboardIHMs/DashboardIHMs"))
);
const IHMItemDashboard = Loadable(
  lazy(() => import("../components/dashboard/ihm/IHMItemDashboard"))
);
const EmailReports = Loadable(
  lazy(() => import("../pages/IHM/EmailReports/EmailReports"))
);
//AppUser
const UserProfilePage = Loadable(
  lazy(() => import("../pages/AppUser/UserProfilePage"))
);
const ShipOwnerSuperUser = Loadable(
  lazy(() => import("../pages/SuperUser/ShipOwnerSuperUser"))
);
const SearchPOItemsPage = Loadable(
  lazy(() => import("../pages/PurchaseOrders/SearchPOItemsPage"))
);