import { useEffect, useState } from "react";
import ShipOwnerAndVesselSelects from "../../../components/ShipOwnerAndVesselSelects";

import { ShipOwnerDTO } from "../../../@types/ShipOwner";
import { ShipOwnerVesselDto } from "../../../@types/ShipOwnerVessel";
import { VesselDTO } from "../../../@types/Vessel";

import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { Icon } from "@iconify/react";
import arrowCircleLeftOutline from "@iconify/icons-eva/arrow-circle-left-outline";
import { Container, Stack, Box, Button } from "@mui/material";
import Page from "../../../components/Page";
import { makeStyles } from "@mui/styles";
import ManageIHMsItems from "./ManageIHMsItems";
import { GET_SHIPOWNERS_QUERY, GET_VESSELS_BY_SHIPOWNER_QUERY } from "../../../graphQL/Queries";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";


const useStyles = makeStyles(() => ({
  navigationIcon: {
    cursor: "pointer",
    color: "#0079C1",
    transition: "0.5s ease",
    "&:hover": {
      color: "#39464f",
      transition: "0.5s ease",
    },
  },
}));


type ManageIHMsProps = {

  isPublic?: boolean;
  setNavigationHelper: React.Dispatch<React.SetStateAction<boolean>>;
};

const ManageIHMsPage = ({

  isPublic,
  setNavigationHelper,
}: ManageIHMsProps) => {
  const [selectedShipOwnerIdAndVesselId, setSelectedShipOwnerIdAndVesselId ] = useState<ShipOwnerVesselDto | undefined>();

  const classesNew = useStyles();



  useEffect(() => {
    setNavigationHelper(false);
  }, []);


  return (
    <Page title="Manage IHMs">
      <Container maxWidth={"xl"} sx={{ p: isPublic ? 10 : 0 }}>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HeaderBreadcrumbs
            heading="Manage IHMs"
            links={
              isPublic
                ? []
                : [
                  { name: "Dashboard" },
                  { name: "IHMs" },
                  { name: "Manage IHMs" },
                ]
            }
          />
          <Icon
            width={45}
            height={45}
            icon={arrowCircleLeftOutline}
            className={classesNew.navigationIcon}

          />
        </Stack>
        <Box sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
          <ShipOwnerAndVesselSelects
            loadFromSessionShipOwnerAndVessels={true}
            selectedShipOwnerId={undefined}
            selectedVesselId={undefined}
            setSelectedShipOwnerIdAndVesselId={setSelectedShipOwnerIdAndVesselId}
            manualSetAndFindShipOwnerAndVessel={false}
            stateOfLoadingFind={false}
          />
        </Box>
        <Box>

          <ManageIHMsItems
            shipOwnerId={
              selectedShipOwnerIdAndVesselId?.shipOwnerDto?.id ? parseInt(selectedShipOwnerIdAndVesselId?.shipOwnerDto.id) : 0
            }
            selectedVesselId={
              selectedShipOwnerIdAndVesselId?.vesselDto?.id ? parseInt(selectedShipOwnerIdAndVesselId?.vesselDto.id) : 0
            }
            isPublic={isPublic}
          />
        </Box>
      </Container>
    </Page>
  );
};


export default ManageIHMsPage;
