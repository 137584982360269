import { useEffect, useState } from "react";
// material
import { useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridColumns,
  GridRowModel,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShipOwnerDTO } from "../../@types/ShipOwner";
import {
  GET_IHM_PENDING_FOR_APPROVAL_ITEMS,
  GET_SHIPOWNERS_QUERY,
  GET_VESSELS_BY_SHIPOWNER_QUERY,
} from "../../graphQL/Queries";
import { parseDate } from "../../utils/formatTime";
import ApproveIhmInstallationMapperModal from "../map/approveIhmInstallation/ApproveIhmInstallationMapperModal";
import ShipOwnerAndVesselSelects from "../ShipOwnerAndVesselSelects";
import { TableListToolbar } from "../table";
import { ShipOwnerVesselDto } from "../../@types/ShipOwnerVessel";

// -------------------------------- Styles ---------------------------------------
const useStyles = makeStyles(() => ({
  customStyle: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "#ffffff",
    },
  },
  panel: {
    "& .MuiNativeSelect-select": {
      backgroundColor: "red",
      color: "red",
    },
  },
  actionBtn: {
    "&.MuiLoadingButton-root": {
      backgroundColor: "#0079C1",
      borderRadius: "25px",
    },
  },
}));

// Define Queries
const getIhmsQuery = (
  searchToken: string,
  selectedShipownerVessel: ShipOwnerVesselDto|undefined,
  limit: number,
  page: number
) =>
  useQuery(GET_IHM_PENDING_FOR_APPROVAL_ITEMS, {
    variables: {
      searchToken: searchToken,
      shipOwnerId: (selectedShipownerVessel && selectedShipownerVessel.shipOwnerDto && selectedShipownerVessel.shipOwnerDto.id) ? parseInt(selectedShipownerVessel.shipOwnerDto.id) : 0,
      vesselId: (selectedShipownerVessel && selectedShipownerVessel.vesselDto && selectedShipownerVessel.vesselDto.id) ? parseInt(selectedShipownerVessel.vesselDto.id) : 0,
      limit: limit,
      page: page,
    },
    onError(err) {
      toast.configure();
      toast.error(`Error: ${err.message}`);
    },
    fetchPolicy: "network-only",
  });

// ------------------------------------------------------------------------------------------------------------------------
export default function PendingForApprovalIhmsTable() {
  // native props
  const classesNew = useStyles();
  const [searchToken, setSearchToken] = useState("");
  const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
  const [isIhmMapperModalOpen, setIsIhmMapperModalOpen] = useState(false);
  const [userRole, setUserRole] = useState<undefined | string>(undefined);
  const { initialized, keycloak } = useKeycloak();
  const [selectedShipOwnerIdAndVesselId, setSelectedShipOwnerIdAndVesselId] = useState<ShipOwnerVesselDto | undefined>();
  // dataGrid props
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);

  const [ihmPositionIdToApprove, setIhmPositionIdToApprove] =
    useState<number>();

  // Use Queries
  const getIhmsQueryResponse = getIhmsQuery(
    searchToken,
    selectedShipOwnerIdAndVesselId,
    rowsPerPage,
    page
  );

  // --------------------------------- hooks -------------------------------------------------
  useEffect(() => {
    if (initialized) {
      if (keycloak.realmAccess) {
        if (keycloak.realmAccess.roles) {
          if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
            setUserRole("ShipOwner");
          } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
            setUserRole("Supplier");
          } else if (
            keycloak.realmAccess.roles.find((x) => x === "Maintainer")
          ) {
            setUserRole("Maintainer");
          }
        }
      } else if (window.sessionStorage.getItem("userRole")) {
        setUserRole(window.sessionStorage.getItem("userRole")?.toString());
      }
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    if (
      getIhmsQueryResponse.data &&
      getIhmsQueryResponse.data.findIhmPendingForApprovalItems
    ) {
      const tableRowsArray: GridRowModel[] = [];

      for (const installedIhm of getIhmsQueryResponse.data
        .findIhmPendingForApprovalItems) {
        const dataTableRow: GridRowModel = {
          id: installedIhm.ihmPositionId,
        };
        dataTableRow.ihmItemId = installedIhm.ihmId;

        dataTableRow.vesselName = installedIhm.vesselName;
        dataTableRow.vesselImo = installedIhm.vesselImo;
        dataTableRow.inventorySubPart = installedIhm.inventorySubPart;
        dataTableRow.equipmentDescription = installedIhm.equipmentDescription;
        dataTableRow.productName = installedIhm.productName;
        dataTableRow.installedQuantity = installedIhm.installedQuantity;
        dataTableRow.location = installedIhm.location;
        dataTableRow.referenceNumber = installedIhm.referenceNumber;
        dataTableRow.location = installedIhm.location;
        dataTableRow.dateAdded =
          typeof installedIhm.dateAdded === "string" &&
          parseDate(installedIhm.dateAdded) !== "Invalid Date"
            ? parseDate(installedIhm.dateAdded)
            : installedIhm.dateAdded;
        dataTableRow.approvalStatus = installedIhm.approvalStatus;
        dataTableRow.requestDate =
          typeof installedIhm.requestDate === "string" &&
          parseDate(installedIhm.requestDate) !== "Invalid Date"
            ? parseDate(installedIhm.requestDate)
            : installedIhm.requestDate;

        dataTableRow.requestBy = installedIhm.requestBy;

        tableRowsArray.push(dataTableRow);
      }

      setDataTableRows(tableRowsArray);
    }
  }, [getIhmsQueryResponse.data]);

  // --------------------------------- handlers -----------------------------------------------
  const handleFilterByName = (filterName: string) => {
    setSearchToken(filterName);
    setPage(0);
  };

  // --------------------------------- Table Setup -----------------------------------------------

  const TABLE_HEAD: GridColumns = [
    {
      field: "vesselName",
      headerName: "Vessel Name",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "vesselImo",
      headerName: "Vessel Imo",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "inventorySubPart",
      headerName: "IHM Sub Part",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "equipmentDescription",
      headerName: "Description",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "productName",
      headerName: "Component / Item",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "installedQuantity",
      headerName: "Installed Quantity",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "location",
      headerName: "Location",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "referenceNumber",
      headerName: "Reference Number",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "dateAdded",
      headerName: "Date Added",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "approvalStatus",
      headerName: "Approval Status",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "requestDate",
      headerName: "Request Date",
      width: 180,
      type: "string",
      sortable: false,
    },
    {
      field: "requestBy",
      headerName: "Request By",
      width: 180,
      type: "string",
      sortable: false,
    },
  ];

  // ------------------------------------------------------------------------------------------
  return (
    <Container maxWidth={"xl"}>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0.5, justifyContent: "space-between" }}
      >
        <Box sx={{ flex: "1" }}>
          <TableListToolbar
            numSelected={0}
            filterName={searchToken}
            onFilterName={handleFilterByName}
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <ShipOwnerAndVesselSelects
            loadFromSessionShipOwnerAndVessels={true}
            selectedShipOwnerId={undefined}
            selectedVesselId={undefined}
            setSelectedShipOwnerIdAndVesselId={setSelectedShipOwnerIdAndVesselId}
            manualSetAndFindShipOwnerAndVessel={false}
            stateOfLoadingFind={false}
          />
        </Box>
        <Box sx={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
          {userRole == "Maintainer" && (
            <LoadingButton
              variant="contained"
              disabled={selectionModel.length != 1 || userRole != "Maintainer"}
              onClick={() => {
                setIhmPositionIdToApprove(
                  selectionModel[0]
                    ? parseInt(`${selectionModel[0]}`)
                    : undefined
                );
                setIsIhmMapperModalOpen(true);
              }}
              className={classesNew.actionBtn}
            >
              Approve
            </LoadingButton>
          )}
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Box
          sx={{
            height: 550,
            width: 1,
            "& .super-app-theme--2": {
              bgcolor: () => "rgba(91, 229, 132, 0.15)",
            },
            "& .super-app-theme--1": {
              bgcolor: () => "rgba(255, 244, 165, 0.5)",
            },
            marginTop: 4,
          }}
        >
          <DataGrid
            columns={TABLE_HEAD}
            rows={dataTableRows}
            className={classesNew.customStyle}
            loading={getIhmsQueryResponse.loading}
            disableColumnFilter
            // --------------------selection props
            disableSelectionOnClick
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) =>
              setSelectionModel(newSelectionModel)
            }
            selectionModel={selectionModel}
            // --------------------pagination props
            pagination
            paginationMode="server"
            rowCount={dataTableRows.length}
            rowsPerPageOptions={[20, 50, 100]}
            pageSize={rowsPerPage}
            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
          />
        </Box>
      </Box>

      {ihmPositionIdToApprove && (
        <ApproveIhmInstallationMapperModal
          isOpenPreview={isIhmMapperModalOpen}
          onClosePreview={() => {
            setIsIhmMapperModalOpen(false);
          }}
          ihmPositionId={ihmPositionIdToApprove}
        />
      )}
    </Container>
  );
}
