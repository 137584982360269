import { useEffect, useState } from "react";
// material
import { Box, Container } from "@mui/material";
//components
// hooks
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import ShipOwnerAndVesselSelects from "../../components/ShipOwnerAndVesselSelects";
import { ShipOwnerDTO } from "../../@types/ShipOwner";
import "react-toastify/dist/ReactToastify.css";
import SupplierComplianceTable from "../../components/dashboard/supplierCompliance/SupplierComplianceTable";
import { ShipOwnerVesselDto } from "../../@types/ShipOwnerVessel";

// ----------------------------- Define Queries ---------------------------------------------------------

// ---------------------------------------------------------------------------------------------------------------------------------------------------------
export default function SupplierCompliancePage() {
  // ShipOwner
  const [selectedShipOwner, setSelectedShipOwner] = useState<ShipOwnerDTO>();
  const [selectedShipOwnerIdAndVesselId, setSelectedShipOwnerIdAndVesselId] = useState<ShipOwnerVesselDto | undefined>();

  // ----------------------------- Use Queries ---------------------------------------------------------


const [stateOfLoadingFind, setStateOfLoadingFind] = useState<boolean>(false);
  // ----------------------------- Component Lifecycle ---------------------------------------------------------

  useEffect(() => {
    if (selectedShipOwnerIdAndVesselId?.shipOwnerDto) {
      setSelectedShipOwner(selectedShipOwnerIdAndVesselId.shipOwnerDto);
    }
  }, [selectedShipOwnerIdAndVesselId])

  // ----------------------------------------------------------------------------------------------
  return (
    <Page title="Supplier's Compliance">
      <Container maxWidth={"xl"}>
        <HeaderBreadcrumbs
          heading="Supplier Compliance"
          links={[{ name: "Dashboard" }, { name: "Supplier Compliance" }]}
        />

        {/* Selects stack box */}
        <Box sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
          <ShipOwnerAndVesselSelects
            loadFromSessionShipOwnerAndVessels={true}
            showVesselSelect={false}
            selectedShipOwnerId={undefined}
            selectedVesselId={undefined}
            setSelectedShipOwnerIdAndVesselId={setSelectedShipOwnerIdAndVesselId}
            manualSetAndFindShipOwnerAndVessel={true}
            stateOfLoadingFind={stateOfLoadingFind}
          />
        </Box>

        <SupplierComplianceTable selectedShipOwnerId={selectedShipOwner?.id} setStateOfLoadingFind={setStateOfLoadingFind}/>
      </Container>
    </Page>
  );
}
