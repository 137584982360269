import { useState, useEffect, useRef, useCallback } from "react";
// material
import {
    Card,
    Box,
    Tooltip,
    Button,
} from "@mui/material";
// utils
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "@apollo/client";
import {
    GET_SUPPLIER_COMPLIANCE_QUERY,
} from "../../../graphQL/Queries";

import { makeStyles } from "@mui/styles";
import { useKeycloak } from "@react-keycloak/web";
import { FilterTypeDTO, OperationValue } from "../../../@types/DataGrid";
import SingleDataGridBar from "../../charts/SingleDataGridBar";
import React from "react";
import { DataGridPro, GridColDef, GridFilterModel, GridFilterPanel, GridRowModel, GridRowSelectionModel, GridSortModel, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles(() => ({
    customStyle: {
        "& .MuiDataGrid-toolbarContainer": {
            background: "#ffffff",
        },
    },
}));


const useGetSupplierComplianceQuery = (
    shipOwnerId: string | undefined,
    limit: number,
    page: number,
    filtersArray: FilterTypeDTO[],
    sortField: string | undefined,
    sortOrder: string | undefined,
) =>
    useQuery(GET_SUPPLIER_COMPLIANCE_QUERY, {
        variables: {
            shipOwnerId: shipOwnerId ? parseInt(shipOwnerId) : 0,
            supplierId: 0,
            limit: limit,
            page: page,
            filtersArray: filtersArray,
            sortingOrder: {
                field: sortField,
                sort: sortOrder,
            },
        },
        skip: !shipOwnerId,
        onError(err) {
            toast.configure();
            toast.error(err.message);
        },
        fetchPolicy: "network-only",
    });

type SupplierComplianceTableProps = {
    setStateOfLoadingFind: React.Dispatch<React.SetStateAction<boolean>>;
    selectedShipOwnerId?: string | undefined;
};

export default function SupplierComplianceTable ({ setStateOfLoadingFind, selectedShipOwnerId }: SupplierComplianceTableProps) {
    // Native props
    const { initialized, keycloak } = useKeycloak();
    const classesNew = useStyles();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [totalRows, setTotalRows] = useState(0);
    const [dataTableRows, setDataTableRows] = useState<GridRowModel[]>([]);
    // datagrid filtering
    const [filtersArray, setFiltersArray] = useState<FilterTypeDTO[]>([]);
    // dataGrid sorting
    const [sortField, setSortField] = useState<string>("name");
    const [sortOrder, setSortOrder] = useState<string>("asc");
    // dataGrid selection
    const [orderedColumns, setOrderedColumns] = useState<GridColDef[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const prevSelectionModel = useRef<GridRowSelectionModel>(selectionModel);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    });
    const apiRef = useGridApiRef();
    const [tableHeadOrdered, setTableHeadOrdered] = useState<GridColDef[]>([]);
    // Get User Role from keycloack
    const [userRole, setUserRole] = useState<undefined | string>(undefined);
    const [filtersPro, setFiltersPro] = React.useState<GridFilterModel>({

        items: [
            {
                field: "name",
                operator: "contains"

            }
        ]
    });


    const [sortingPro, setSortingPro] = React.useState<GridSortModel>(
        [{ "field": "name", "sort": "asc" }]
    );

    // Use Queries
    const useGetSupplierComplianceQueryResponse = useGetSupplierComplianceQuery(
        selectedShipOwnerId,
        rowsPerPage,
        page,
        filtersArray,
        sortField,
        sortOrder,
    )

    // --------------------------------- Mutations -------------------------------------------------

    // --------------------------------- Component Lifecycle ------------------------------------------------------
    useEffect(() => {
        if (useGetSupplierComplianceQueryResponse?.data?.getSupplierCompliance) {
            if (useGetSupplierComplianceQueryResponse.data.getSupplierCompliance.rows) {
                const dataTableRowsArray: GridRowModel[] = [];
                for (const supplierCompliance of useGetSupplierComplianceQueryResponse.data.getSupplierCompliance.rows) {
                    const dataTableRow = {
                        id: supplierCompliance.id,
                        supplierId: supplierCompliance.supplierId,
                        shipOwnerId: supplierCompliance.shipOwnerId,
                        name: supplierCompliance.name,
                        country: supplierCompliance.country,
                        city: supplierCompliance.city,
                        phoneNumber: supplierCompliance.phoneNumber,
                        compliance: supplierCompliance.compliance,
                        responseRate: supplierCompliance.responseRate,
                        numberOfPOs: supplierCompliance.numberOfPOs,
                        inScopePOs: supplierCompliance.inScopePOs,
                        isValid: supplierCompliance.isValid,
                        supplierEmail: supplierCompliance.supplierEmail,
                        totalPendingPos: supplierCompliance.totalPendingPos,
                        shipOwnerName: supplierCompliance.shipOwnerName
                    };
                    dataTableRowsArray.push(dataTableRow);
                }
                setDataTableRows(dataTableRowsArray);
            }
            if (useGetSupplierComplianceQueryResponse?.data?.getSupplierCompliance.totalRows) {
                setTotalRows(useGetSupplierComplianceQueryResponse.data.getSupplierCompliance.totalRows);
            }
        }
    }, [useGetSupplierComplianceQueryResponse.data])

    useEffect(() => {
        const { page, pageSize } = paginationModel;
        setPage(page);
        setRowsPerPage(pageSize);

    }, [paginationModel]);

    
    useEffect(()=>{
        setStateOfLoadingFind(useGetSupplierComplianceQueryResponse?.loading)
    },[useGetSupplierComplianceQueryResponse?.loading]);

    

    useEffect(() => {
        if (initialized) {
            if (keycloak.realmAccess) {
                if (keycloak.realmAccess.roles) {
                    if (keycloak.realmAccess.roles.find((x) => x === "ShipOwner")) {
                        setUserRole("ShipOwner");
                    } else if (keycloak.realmAccess.roles.find((x) => x === "Supplier")) {
                        setUserRole("Supplier");
                    } else if (keycloak.realmAccess.roles.find((x) => x === "Maintainer")) {
                        setUserRole("Maintainer");
                    }
                }
            } else if (window.sessionStorage.getItem('userRole')) {
                setUserRole(window.sessionStorage.getItem('userRole')?.toString());
            }
        }
    }, [keycloak, initialized])

    const exportState = React.useCallback(() => {
        const state = apiRef.current.exportState();
        if (window.localStorage) {
            localStorage.setItem("manageSupplierComplianceDashBoard", JSON.stringify(state));
        }
    }, [apiRef]);

    React.useEffect(() => {
        try {
            const TABLE_COLUMNS: GridColDef[] = [
                {
                    field: "name",
                    headerName: "Name",
                    width: 180,
                    filterable: true,
                    sortable: true,
                },
                {
                    field: "shipOwnerName",
                    headerName: "Shipowner Name",
                    width: 180,
                    filterable: true,
                    sortable: true,
                },
                {
                    field: "country",
                    headerName: "Country",
                    width: 180,
                    filterable: true,
                    sortable: true,
                },
                {
                    field: "supplierEmail",
                    headerName: "Supplier email",
                    width: 180,
                    filterable: true,
                    sortable: true,
                },
                {
                    field: "phoneNumber",
                    headerName: "Phone Number",
                    width: 180,
                    filterable: true,
                    sortable: true,
                },
                {
                    field: "compliance",
                    headerName: "Compliance %",
                    width: 180,
                    filterable: false,
                    sortable: true,
                    renderCell(params) {
                        let colorOfBar = "#0079C1"
                        if (params.value <= 33.3) {
                            colorOfBar = "#d94c4c"
                        } else if (params.value > 33.3 && params.value <= 66.6) {
                            colorOfBar = "#d7d94c"
                        } else if (params.value > 66.6) {
                            colorOfBar = "#4cd964"
                        }
                        return (
                            <Tooltip title={`${params.value} %`}>
                                <Box sx={{ mt: 3, ml: -3 }}>
                                    <SingleDataGridBar
                                        value={params.value}
                                        barColor={colorOfBar}
                                    />
                                </Box>
                            </Tooltip>
                        )
                    },
                },
                {
                    field: "responseRate",
                    headerName: "Response Rate %",
                    width: 180,
                    filterable: false,
                    sortable: true,
                    renderCell(params) {
                        let colorOfBar = "#0079C1"
                        if (params.value <= 33.3) {
                            colorOfBar = "#d94c4c"
                        } else if (params.value > 33.3 && params.value <= 66.6) {
                            colorOfBar = "#d7d94c"
                        } else if (params.value > 66.6) {
                            colorOfBar = "#4cd964"
                        }
                        return (
                            <Tooltip title={`${params.value} %`}>
                                <Box sx={{ mt: 3, ml: -3 }}>
                                    <SingleDataGridBar
                                        value={params.value}
                                        barColor={colorOfBar}
                                    />
                                </Box>
                            </Tooltip>
                        )
                    },
                },
                {
                    field: "inScopePOs",
                    headerName: "Total POs",
                    width: 180,
                    filterable: false,
                    sortable: true,
                },
                {
                    field: "totalPendingPos",
                    headerName: "Pending POs",
                    width: 180,
                    filterable: false,
                    sortable: true,
                }
            ];

            const savedData = localStorage.getItem("manageSupplierComplianceDashBoard");

            if (savedData) {

                const savedDataJsonFormated = JSON.parse(savedData != undefined ? savedData : "{}");
                setOrderedColumns([]);


                if (TABLE_COLUMNS != undefined) {

                    for (const field of savedDataJsonFormated.columns.orderedFields) {

                        if (field != "__check__") {

                            const savedColumn = TABLE_COLUMNS.find((obj) => {
                                return obj.field === field;
                            });
                            if (savedColumn != undefined) {
                                savedColumn.width = savedDataJsonFormated.columns.dimensions[field]["width"];
                                if (field == "compliance" && dataTableRows.length > 0) {
                                    savedColumn.renderCell =
                                        (params: any) => {

                                            let colorOfBar = "#0079C1"
                                            if (params.value <= 33.3) {
                                                colorOfBar = "#d94c4c"
                                            } else if (params.value > 33.3 && params.value <= 66.6) {
                                                colorOfBar = "#d7d94c"
                                            } else if (params.value > 66.6) {
                                                colorOfBar = "#4cd964"
                                            }
                                            return (
                                                <Tooltip title={`${params.value} %`}>
                                                    <Box sx={{ mt: 3, ml: -3 }}>
                                                        <SingleDataGridBar
                                                            value={params.value}
                                                            barColor={colorOfBar}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            )

                                        };



                                } else if (field == "responseRate" && dataTableRows.length > 0) {
                                    savedColumn.renderCell =
                                        (params: any) => {
                                            let colorOfBar = "#0079C1"
                                            if (params.value <= 33.3) {
                                                colorOfBar = "#d94c4c"
                                            } else if (params.value > 33.3 && params.value <= 66.6) {
                                                colorOfBar = "#d7d94c"
                                            } else if (params.value > 66.6) {
                                                colorOfBar = "#4cd964"
                                            }
                                            return (
                                                <Tooltip title={`${params.value} %`}>
                                                    <Box sx={{ mt: 3, ml: -3 }}>
                                                        <SingleDataGridBar
                                                            value={params.value}
                                                            barColor={colorOfBar}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            );
                                        };
                                }
                            }

                            orderedColumns.push(savedColumn != undefined ? savedColumn : JSON.parse('{}'));
                        }

                    }

                    setTableHeadOrdered(orderedColumns);


                }
            } else {
                setTableHeadOrdered(TABLE_COLUMNS);
            }

        } catch (error) {
            console.log(error)
        }
    }, [apiRef]);






    // --------------------------------- handlers ---------------------------------------------------
    const clearFiltersAndSorting = () => {
        setFiltersArray([]);
        setSortField("name");
        setSortOrder("asc");
        setFiltersPro({

            items: [
                {
                    field: "name",
                    operator: "contains"

                }
            ]
        });
        setSortingPro([{ "field": "name", "sort": "asc" }]);
    }

    // sorting handler
    const handleSortModelChange = (newModel: GridSortModel) => {
        setSortingPro(newModel);
        if (
            newModel !== null &&
            newModel != undefined &&
            newModel.length == 1 &&
            newModel[0].sort &&
            newModel[0].field
        ) {
            setSortField(newModel[0].field);
            setSortOrder(newModel[0].sort?.toString());
        }
    };
    // filtering handler
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        const properFilterArray: FilterTypeDTO[] = [];
        setFiltersPro(filterModel);
        filterModel.items.forEach((filterObject) => {
            let opVal = OperationValue.UNKNOWN;
            if (
                filterObject.field != undefined &&
                filterObject.operator != undefined &&
                filterObject.value != undefined
            ) {
                let opVal = OperationValue.UNKNOWN;
                switch (filterObject.operator) {
                    case "contains":
                        opVal = OperationValue.CONTAINS;
                        break;
                    case "equals":
                        opVal = OperationValue.EQUALS;
                        break;
                    case "startsWith":
                        opVal = OperationValue.STARTS_WITH;
                        break;
                    case "endsWith":
                        opVal = OperationValue.ENDS_WITH;
                        break;
                    default:
                        break;
                }
                const properFilter: FilterTypeDTO = {
                    columnField: filterObject.field,
                    operatorValue: opVal,
                    value: filterObject.value,
                };
                properFilterArray.push(properFilter);
            } else if (filterObject.operator == "isEmpty" || filterObject.operator == "isNotEmpty") {
                switch (filterObject.operator) {
                    case "isEmpty":
                        opVal = OperationValue.IS_EMPTY;
                        break;
                    case "isNotEmpty":
                        opVal = OperationValue.IS_NOT_EMPTY;
                        break;
                    default:
                        break;
                }
                const properFilter: FilterTypeDTO = {
                    columnField: filterObject.field,
                    operatorValue: opVal,
                    value: opVal == 4 || opVal == 5 ? '' : filterObject.value,
                };
                properFilterArray.push(properFilter);
            }
        });
        if (properFilterArray.length > 0) {
            setFiltersArray(properFilterArray);
        }
    }, []);

    // ------------------------------------------------ Table Header ---------------------------------------------------------------------------------
    const handleCellClick = (params: any) => {

        if (params.field === 'name' && params.rowIndex !== -1) {
          // Redirect to a different page when clicking on the  column
          
          navigate(`/dashboard/suppliers/${params.row.supplierId}/edit/mainDash/0`)
           }
      };



    return (
        <div>
            <Card sx={{ minHeight: "530px" }}>
                <Button
                    sx={{ mt: 1, ml: 1 }}
                    variant="contained"
                    component="label"
                    onClick={() => clearFiltersAndSorting()}
                    disabled={filtersArray.length == 0 && sortOrder == 'asc' && sortField == 'name'}
                >Clear filters</Button>
                <Box
                    sx={{
                        height: 550,
                        width: 1,
                        "& .super-app-theme--2": {
                            bgcolor: () => "rgba(91, 229, 132, 0.15)",
                        },
                        "& .super-app-theme--1": {
                            bgcolor: () => "rgba(255, 244, 165, 0.5)",
                        },
                    }}
                >
                    <DataGridPro

                        onCellClick={handleCellClick}
                        columns={tableHeadOrdered}
                        apiRef={apiRef}
                        rows={dataTableRows}
                        disableRowSelectionOnClick
                        components={{
                            Toolbar: GridToolbar,
                            FilterPanel: GridFilterPanel,
                        }}
                        onColumnVisibilityModelChange={exportState}
                        onColumnOrderChange={exportState}
                        onColumnWidthChange={exportState}
                        className={classesNew.customStyle}
                        // // selection props
                        onRowSelectionModelChange={(newSelectionModel) =>
                            setSelectionModel(newSelectionModel)
                        }
                        rowSelectionModel={selectionModel}
                        // filtering props
                        filterMode="server"
                        filterModel={filtersPro}
                        onFilterModelChange={onFilterChange}
                        // sorting props
                        sortingMode="server"
                        sortModel={sortingPro}
                        onSortModelChange={handleSortModelChange}
                        // pagination props
                        pagination
                        paginationMode="server"
                        rowCount={totalRows}

                        pageSizeOptions={[100, 200, 300]}

                        paginationModel={{ pageSize: rowsPerPage, page: page }}

                        onPaginationModelChange={setPaginationModel}
                        loading={useGetSupplierComplianceQueryResponse?.loading}
                    />
                </Box>

            </Card>


        </div>
    );
}